import React from 'react';
import Home from './pages/Home';
import CategoryDetails from './pages/CategoryDetails';
import DistrictCultural from './pages/DistrictCultural/DistrictCultural';
import DistrictCategoryDetails from './pages/DistrictCultural/DistrictCategoryDetails';

import {  BrowserRouter as Router,Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Add more routes as needed */}
      <Route path="/maritime-odisha-details" element={<CategoryDetails />} />
      <Route path="/maritime-india" element={< DistrictCultural />} />
      <Route path="/maritime-india-details" element={< DistrictCategoryDetails />} />
    </Routes>
    </Router>
  );
}

export default App;
