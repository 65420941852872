import React, { useState,useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Button from '@mui/material/Button';

import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Sidebar from "./Include";
import { mainListItems } from "./ListItem";
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// const zigzagData = [
//   {
//     type: "image",
//     src: "/screenshot1.png",
//     alt: "Buddhist Map",
//   },
//   {
//     type: "text",
//     content:
//       "The “Diamond Triangle” which consists of Ratnagiri, Udayagiri, and Lalitagiri, is considered to be one of the richest areas for the important Buddhist ruins of ancient times. A large-scale excavation has unearthed two large monasteries, Buddhist shrines, sculptures, and a large number of votive stupas.",
//   },
 
//   // Add more items as needed
// ];
// const zigzagData2 = [
   
//     {
//       type: "text",
//       content:
//         "The “Diamond Triangle” which consists of Ratnagiri, Udayagiri, and Lalitagiri, is considered to be one of the richest areas for the important Buddhist ruins of ancient times. A large-scale excavation has unearthed two large monasteries, Buddhist shrines, sculptures, and a large number of votive stupas.",
//     },
//     {
//         type: "image",
//         src: "/screenshot4.png",
//         alt: "Buddhist Map",
//       },
//     // Add more items as needed
//   ];
  
const HorizontalLine = styled('hr')({
  borderTop: '1px solid #ccc',
  width: '100%',
  margin: '10px 0',
});

const VerticalLine = styled("div")({
  borderLeft: "1px solid #ccc",
  height: "100%",
  margin: "0 16px",
});
function Copyright(props) {
  return (
    <Typography variant='body2' noWrap  sx={{pt:1,pb:1}}  align="center" >
    {'Copyright © '} Designed by
    <span  noWrap  style={{ fontWeight: 'bolder'}}>  Geo Infotech</span>
 
  {' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>

  );
}
export default function ZigzagPage() {
    const location = useLocation();
    const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = React.useState("one");
  const [zoomedImageSrc, setZoomedImageSrc] = useState([]);
  const [zigzagData, setzigzagData] = useState([]);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [caption, setcaption] = useState('');
  const handleOpenModal = (imgcaption,imageSrc) => {
    setSelectedImage(imageSrc);
    setcaption(imgcaption)
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setcaption('')
    setModalOpen(false);
  };
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlebacknavigation = () => {
    // Perform any necessary actions before navigating
    // For now, let's just navigate to "/inner-page"
    navigate('/');
  };
  const params = new URLSearchParams(location.search);
  const querySelectedItem = params.get('selectedItem');
  const [selectedItem, setSelectedItem] = useState(querySelectedItem);
  useEffect(() => {
  //  console.log(selectedItem)
   fetchData(selectedItem)
   
  }, [selectedItem]);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    console.log(`Item clicked: ${itemName}`);
    // Additional logic for handling the click if needed
   fetchData(itemName)
  };
  const fetchData = async (itemName) => {
    setLoading(true);
    try {
    
      const requestData = {
        method: 'odisha_map_details',
        category: selectedItem,
     
      };
  
      const response = await fetch('https://maritime-history.examot.com/api/v1.php', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
  
      if (data.code == '200') {
          setLoading(false);
        // setCaption(data.data.caption);
        // setZoomedImageSrc(data.data.picture);
        setzigzagData(data.data)
        // console.log(data.data.picture)
      } else {
        // Handle other status codes or error scenarios
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error scenarios
    }
  };
  
  return (
    <ThemeProvider theme={createTheme()}>
    <Box sx={{ display: "flex",minHeight:'100vh', overflow:'hidden' }}>
      <CssBaseline />
      <Sidebar selectedItem={selectedItem} handleItemClick={handleItemClick}/>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: "100vh",
          
        //   overflow: "auto",
        }}
      >
        <Toolbar />
        {loading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
  
        <Container maxWidth="lg" sx={{ mt: 6,mb: 8}}>
        <Box sx={{ display: 'flex',marginBottom:'20px' }}>
            <ArrowBackIcon sx={{ color: '#005AC1', fontSize: 20, cursor: 'pointer',marginTop:'6px' }} onClick={handlebacknavigation} />
            <Typography sx={{ fontSize: '14px', color: '#005AC1', fontWeight: '500', cursor: 'pointer' ,marginTop:'6px'}} onClick={handlebacknavigation}>Go back</Typography>
     
          </Box>
          {/* <Grid container spacing={2}>
            {zigzagData.map((item, index) => (
              <Grid item xs={12} sm={8} md={6} key={index}>
                <Box>
                  {item.type === "image" && (
                    <img
                      src={item.src}
                      alt={item.alt}
                      style={{ width: "100px",  height: "100px" }} // Set max width for the image
                    />
                  )}
                  {item.type === "text" && (
                    <div style={{ flex: 1 }}>
                      <Typography variant="body2">{item.content}</Typography>
                    </div>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid> */}
          
          <Box >
      {zigzagData.map((item, index,array) => (
     <React.Fragment key={index}>
   
       <Box style={{ alignItems: "flex-start", }}>
         <img
           src={item.picture}
           alt={item.alt}
           style={{
            width: "25%",
            float:'left',
          
          //   height: "100px",
             marginRight: "16px",
             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
             borderRadius: "8px",
           }}
           
           onClick={() => handleOpenModal(item.caption,item.picture)}
         />
       
</Box>
  <Box>
  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{item.caption}</Typography>

<Typography variant="body2" sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.description }} />
</Box>

<div style={{ clear: 'both' }} />
    
      {index !== array.length - 1 && (
        
                    <HorizontalLine />
                    
                  )}
   </React.Fragment>
))}
 

    </Box>
   


    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, alignItems:'center', backgroundColor:'white', width: '100%',zIndex:'99999' }}>

 <Container>
 <Copyright  />
 </Container>
</Paper>
        </Container>
      </Box>
    </Box>
    <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{caption}</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected Modal"
              style={{ maxWidth: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
  </ThemeProvider>
  );
}
