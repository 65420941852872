
// import { mainListItems } from './ListItem';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
const drawerWidth = 180;

function ResponsiveDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const [value, setValue] = React.useState('one');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(props.selectedItem); // Initialize with the prop value
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerItemClick = (itemName) => {
   setSelectedItem(itemName);
   props.handleItemClick(itemName);
  // Additional logic for handling the click if needed
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const [sidebarItems, setSidebarItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('https://odisha-cultural-atlas.examot.com/api/v1.php', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     method: 'odisha_map',
        //     category: '',
        //   }),
        // });
        const requestData = {
          method: 'odisha_map',
          category: '',
       
        };
    
        const response = await fetch('https://maritime-history.examot.com/api/v1.php', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
  
        if (data.code == '200') {
       
          setSidebarItems(data.data.sidebar);
          if(selectedItem==''){
            if (data.data.sidebar.length > 0) {
           setSelectedItem(data.data.sidebar[0].slug);
            }
          }
        } else {
          // Handle other status codes or error scenarios
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error scenarios
      }
    };
  
    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts
  
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
       
  <List
    component="nav"
 
    sx={{ marginTop: '25px',marginBottom: '25px', overflow: "hidden" }}
  >
    <React.Fragment >
   
    {sidebarItems.map((item) => (
      <ListItemButton noWrap  sx={{
        paddingLeft: 0, // No padding on the right
        marginLeft: '-40px',  // No margin on the right
      }}  key={item.slug} selected={selectedItem === item.slug}  onClick={() => handleDrawerItemClick(item.slug)} >
        <ListItemIcon>

        </ListItemIcon>
        <ListItemText  primary={item.title} />
      </ListItemButton>
     ))}
   

    </React.Fragment>
  </List>
     
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,backgroundColor: 'white', }}>
      <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon  sx={{color:'black'}}/>
         
          </IconButton>
         
          <img
  src="/logo-odisha.png"
  alt=""
  srcSet=""
  style={{
    width: '60.001px',
    height: '60px',
    '@media (max-width: 400px)': {
      width: '40px', // Adjust the width for smaller screens
      height: '40px', // Adjust the height for smaller screens
    },
  }}
/>

          {!isSmallScreen && (
  <Typography variant="h6" noWrap sx={{ fontSize: 14, marginLeft: '5px', color: 'black' }}>
   Maritime history of Odisha
   <br />
    <Typography variant="body2" sx={{ fontSize: 12 }}>
      Government of Odisha
    </Typography>
  </Typography>
)}

 <Box sx={{ flexGrow: 1, marginLeft: '40px',overflowX: 'auto' }}>
  <AppBar
    position="static"
    sx={{ backgroundColor: 'transparent', boxShadow: 'none', display: 'flex', justifyContent: 'center' }}
  >
    <Tabs
      value={value}
      onChange={handleChange}
      centered
      sx={{ fontSize: isSmallScreen ? 12 : 14 }}
    >
      <Tab
 label={isSmallScreen ? 'Maritime Odisha' : 'Maritime Odisha'}
      onClick={() => navigate('/')}
      sx={{ fontSize: isSmallScreen ? 12 : 14 }}
      value="one"
    />
    <Tab
       label={isSmallScreen ? 'Maritime India' : 'Maritime India'}
      onClick={() => navigate('/maritime-india')}
      sx={{ fontSize: isSmallScreen ? 12 : 14 }}
      value="two"
    />
    </Tabs>
    
  </AppBar>
  
</Box>
<Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="fullscreen"
              onClick={handleFullScreenToggle}
            >
              {isFullScreen ? (
                <FullscreenExitIcon sx={{ color: 'black' }} />
              ) : (
                <FullscreenIcon sx={{ color: 'black' }} />
              )}
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
